<template>
	<div style="height: 100%;">
		<div class="pc-main">
			<div class="main-table">
				<el-form :inline="true" :size="theme.size">
					<el-form-item>
						<el-date-picker v-model="params.date" :picker-options="$config.DATE_PICKER" @change="getFees(params)" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
					</el-form-item>
					<el-form-item>
						<el-select v-model="params.category_id" placeholder="分类" @change="getFees(params)" filterable clearable>
							<el-option v-for="category in categories" :key="category.id" :label="category.name" :value="category.id"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-cascader style="width: 100%;" placeholder="请选择所属部门" v-model="params.departments" :options="tree_departments" :props="{expandTrigger: 'hover', value: 'unique_id', label: 'name', checkStrictly: true}" @change="getFees(params)" filterable clearable></el-cascader>
					</el-form-item>
					<el-form-item>
						<el-checkbox v-model="params.new" :true-label="1" :false-label="0" @change="getFees(params)" border>当月客户</el-checkbox>
					</el-form-item>
					<el-form-item>
						<el-checkbox v-model="params.oneself" :true-label="1" :false-label="0" @change="getFees(params)" border>自主开发</el-checkbox>
					</el-form-item>
				</el-form>
				<div style="position: relative; height: 100%;">
					<el-table ref="fffff" class="scroll-wrapper ssss absolute" :max-height="(theme.height - 160)+'px'" :header-cell-style="{backgroundColor: '#e1d6ba', color: '#000'}" :data="goods" :size="theme.size" @sort-change="sortChange" show-summary>
						<el-table-column label="业务销售" prop="name" min-width="90"></el-table-column>
						<el-table-column align="center" v-for="type in goods_types" :key="type.id" :label="type.name">
							<el-table-column :prop="'count_'+type.id" label="家" min-width="40" align="center"></el-table-column>
							<el-table-column :prop="'quantity_'+type.id" label="张" min-width="40" align="center"></el-table-column>
							<el-table-column :prop="'price_'+type.id" label="金额" min-width="88" align="center" sortable="custom"></el-table-column>
						</el-table-column>
						<el-table-column label="其它" align="center">
							<el-table-column prop="count_other" label="家" min-width="40" align="center"></el-table-column>
							<el-table-column prop="quantity_other" label="张" min-width="40" align="center"></el-table-column>
							<el-table-column prop="price_other" label="金额" min-width="88" align="center" sortable="custom"></el-table-column>
						</el-table-column>
						<el-table-column label="合计" align="center">
							<el-table-column prop="count" label="家" min-width="40" align="center"></el-table-column>
							<el-table-column prop="quantity" label="张" min-width="40" align="center"></el-table-column>
							<el-table-column prop="price" label="金额" min-width="88" align="center" sortable="custom"></el-table-column>
						</el-table-column>
					</el-table>
				</div>
			</div>
		</div>
	</div>
</template>

<style type="">
	.ssss .el-table__footer-wrapper tbody td.el-table__cell {
		background-color: #c7000a;
		font-weight: 600;
		font-size: 13px;
		color: #fff;
	}
</style>

<script>
	import { mapState } from 'vuex';

	export default {
		computed: {
			...mapState(['theme', 'oa_departments']),
			tree_departments () {
				return this.$utils.buildTree(Object.values(this.oa_departments));
			}
		},
		methods: {
			cellStyle ({row}) {
				if (row.name == '公海') return {color: '#c7000a', fontWeight: 900};
			},
			sortChange (e) {
				let o = e.order ? (e.prop+':'+e.order) : null;
				let p = this.params;
					p.order = o;
				this.getFees(this.params);
			},
			async getFees (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_CLIENTS_FEES, {params: {...params, action: 'statistics'}, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.categories = result.categories;
				this.goods_types = result.goods_types;
				this.goods = result.goods;
			},
			getSummaries(param) {
				const { columns, data } = param;
				const sums = [];
				columns.forEach((column, index) => {
					if (index === 0) {
						sums[index] = '合计';
						return;
					}
					const values = data.map(item => Number(item[column.property]));
					if (!values.every(value => isNaN(value))) {
						sums[index] = values.reduce((prev, curr) => {
							const value = Number(curr);
							if (!isNaN(value)) {
								return prev + curr;
							} else {
								return prev;
							}
						}, 0);
					} else {
						sums[index] = 'N/A';
					}
					sums[5] = ((sums[3]/sums[1])*100).toFixed(2);
					sums[6] = ((sums[4]/sums[1])*100).toFixed(2);
				});
				return sums;
			}
		},
		data() {
			const start = new Date();
			start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
			return {
				categories: [],
				goods_types: [],
				goods: [],
				params: {
					date: [start, new Date()]
				}
			}
		},
		async created () {
			this.getFees(this.params, true);
		}
	};
</script>